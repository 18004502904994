<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row
            align-h="between"
            align-v="center"
            class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                {{ $t('Pedidos') }}
              </h3>
            </b-col>
            <b-col cols="auto">
              <div
                v-if="currentUser.roles[0].name === 'admin' || currentUser.roles[0].name === 'user'"
                class="d-flex align-items-center"
              >
                <ButtonOrders />
              </div>
            </b-col>
          </b-row>
          <hr>
          <!-- filters -->
          <div class="p-1">
            <b-row>
              <b-col
                v-if="currentUser.roles[0].name === 'super_admin'"
                cols="12"
                lg="4"
                md="4"
              >
                <b-form-group label-for="filter-client">
                  <SelectClients v-model="client" />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="4"
                md="4"
              >
                <b-form-group label-for="filter-status">
                  <v-select
                    v-model="status"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectStatus"
                    :placeholder="$t('orders.search_by_status')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <!-- table -->
          <b-table
            hover
            responsive
            :items="items"
            :fields="seleccionarcolumnas"
            :no-local-sorting="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="table-white"
          >
            <template #cell(id)="data">
              <b-link
                :to="{ name: 'viewOrders', params: { id: data.item.id } }"
                class="link"
              >
                {{ numberFormat( data.item ) }}
              </b-link>
            </template>
            <template #cell(amount)="data">
              {{ priceFormatted(data.item.amount) }} €
            </template>
            <template #cell(created_at)="data">
              {{ dateColumn(data.item.created_at) }}
            </template>
            <template #cell(updated_at)="data">
              {{ dateColumn(data.item.updated_at) }}
            </template>
            <template
              v-if="currentUser.roles[0].name === 'super_admin'"
              #cell(client)="data"
            >
              <span v-if="data.item.client">
                <b-link :to="{ name: 'viewClient', params: { id: data.item.client.id } }">{{ data.item.client.name }}
                </b-link>
              </span>
            </template>
            <template #cell(status)="data">
              <b-row
                class="align-items-center justify-content-center"
                style="width: 200px"
              >
                <b-col
                  cols="3"
                  class="text-center"
                >
                  <iconPreparacion
                    v-if="data.item.status.type === 'ready'"
                    class="icon-status"
                  />
                  <icon-entregado
                    v-if="data.item.status.type === 'delivered'"
                    class="icon-status-entregado"
                  />
                  <icon-enviado
                    v-if="data.item.status.type === 'send'"
                    class="icon-status"
                  />
                  <icon-reparto
                    v-if="data.item.status.type === 'cast'"
                    class="icon-status"
                  />
                  <icon-review v-if="data.item.status.type === 'revision'" />
                  <feather-icon
                    v-if="data.item.status.type === 'budget' || data.item.status.type === 'budget_admin'"
                    icon="FileTextIcon"
                    size="18"
                    class="mr-50 icon-status"
                  />
                  <feather-icon
                    v-if="data.item.status.type === 'pending'"
                    icon="EditIcon"
                    size="18"
                    class="mr-50 icon-status"
                  />
                </b-col>
                <b-col class="ml-1">
                  <div>
                    <strong>
                      <span>
                        {{ $t(`status.orders.${data.item.status.type}`) }}
                      </span>
                    </strong>
                  </div>
                  <span>{{ dateColumn(data.item.status.created) }}</span>
                </b-col>
              </b-row>
            </template>
            <template #cell(budget)="data">
              <b-link
                v-if="data.item.budget"
                :href="data.item.budget.path"
                target="_blank"
                class="d-inline-block px-50"
              >
                <span>{{ $t('orders.see_quote') }}</span>
              </b-link>
              <b-link
                v-if="data.item.delivery_note"
                :href="data.item.delivery_note.path"
                target="_blank"
                class="d-inline-block px-50"
              >
                <span>{{ $t('orders.see_delivery_note') }}</span>
              </b-link>
              <b-link
                v-if="data.item.invoice"
                :href="data.item.invoice.path"
                target="_blank"
                class="d-inline-block px-50"
              >
                <span>{{ $t('orders.see_invoice') }}</span>
              </b-link>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewOrders', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link
                  v-if="currentUser.roles[0].name === 'super_admin'"
                  :to="{ name: 'editOrders', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  v-if="['budget', 'pending', 'delivered'].includes(data.item.status.type)"
                  class="d-inline-block px-50 text-dark"
                  @click="goToOrder(data.item.id)"
                >
                  <feather-icon icon="EditIcon" />
                </span>
                <span
                  v-if="deletePermission(data.item)"
                  class="d-inline-block px-50 text-danger"
                  @click="deleteOrder(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            v-if="totalItems > pageLength"
            class="d-sm-flex d-block align-items-center justify-content-sm-between justify-content-center mb-1 px-2 pt-1 pt-sm-0"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                {{ $t('orders.pagination_per_page') }}:
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalShow"
      :hide-footer="true"
    >
      <h4 class="text-center">
        {{ $t('orders.modal_mobile_text') }}
      </h4>
      <h5 class="text-center">
        {{ $t('orders.modal_mobile_warning') }}
      </h5>
      <b-row class="text-center mb-1">
        <b-col>
          <b-link
            :to="{ name: 'products' }"
            class="btn btn-primary m-1"
          >
            OK
          </b-link>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BCard,
  BButton,
  BTable,
  BCol,
  BRow, BLink, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { config } from '@/shared/app.config'
import { TimestampToFlatPickr, TimestampToFlatPickrOnlyYear } from '@/libs/helpers'
import iconPreparacion from '@/assets/images/status/preparacion'
import iconEnviado from '@/assets/images/status/enviado'
import iconReparto from '@/assets/images/status/reparto'
import iconReview from '@/assets/images/status/review'
import iconEntregado from '@/assets/images/status/entregado'
import vSelect from 'vue-select'
import ButtonOrders from '@/views/steps/ButtonOrders'
import SelectClients from '@/components/clients/select/ClientsSelect.vue'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormSelect,
    BFormGroup,
    iconPreparacion,
    iconReview,
    iconEnviado,
    iconReparto,
    iconEntregado,
    vSelect,
    ButtonOrders,
    SelectClients,
  },
  data() {
    return {
      log: [],
      sortBy: '',
      sortDesc: '',
      currentPage: 1,
      pageLength: 10,
      dir: false,
      statusOrderVariant: config.statusOrderVariants,
      webApiBase: config.webApiBase,
      pages: ['10', '15', '25'],
      columns_admin: [
        {
          label: this.$t('orders.number'),
          key: 'id',
          sortable: true,
        },
        {
          label: this.$t('orders.date'),
          key: 'created_at',
          sortable: true,
        },
        {
          label: this.$t('orders.updated_at'),
          key: 'updated_at',
          sortable: true,
        },
        {
          label: this.$t('orders.num_products'),
          key: 'num_products',
          sortable: true,
        },
        {
          label: this.$t('orders.price'),
          key: 'amount',
        },
        {
          label: this.$t('orders.status'),
          key: 'status',
        },
        {
          label: this.$t('orders.client'),
          key: 'client',
        },
        {
          label: this.$t('orders.files'),
          key: 'budget',
        },
        {
          label: this.$t('orders.actions'),
          key: 'actions',
          class: 'column-actions',
        },
      ],
      columns: [
        {
          label: this.$t('orders.number'),
          key: 'id',
          sortable: true,
        },
        {
          label: this.$t('orders.date'),
          key: 'created_at',
          sortable: true,
        },
        {
          label: this.$t('orders.updated_at'),
          key: 'updated_at',
          sortable: true,
        },
        {
          label: this.$t('orders.num_products'),
          key: 'num_products',
          sortable: true,
        },
        {
          label: this.$t('orders.price'),
          key: 'amount',
        },
        {
          label: this.$t('orders.status'),
          key: 'status',
        },
        {
          label: this.$t('orders.files'),
          key: 'budget',
        },
        {
          label: this.$t('orders.actions'),
          key: 'actions',
          class: 'column-actions',
        },
      ],
      searchTerm: '',
      status: '',
      client: '',
      modalShow: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'orders/getItems',
      totalItems: 'orders/getTotalItems',
      currentUser: 'auth/getUser',
      selectStatus: 'orders/getSelectStatus',
    }),
    seleccionarcolumnas() {
      if (this.currentUser.roles[0].name === 'super_admin') {
        return this.columns_admin
      }
      return this.columns
    },
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
    user() {
      this.currentPage = 1
      this.chargeData()
    },
    client() {
      this.currentPage = 1
      this.chargeData()
    },
    project() {
      this.currentPage = 1
      this.chargeData()
    },
    date_fin() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.currentPage = 1
      this.chargeData()
    },
    orderby() {
      this.currentPage = 1
      this.chargeData()
    },
    status() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  async created() {
    console.log(window.screen.width)
    if (window.screen.width <= 768) {
      this.modalShow = true
      console.log(true)
    } else {
      await this.chargeData()
      await this.getSelectStatus({
        type: 'order',
      })
    }
  },
  methods: {
    ...mapActions({
      list: 'orders/getListOrders',
      delete: 'orders/delete',
      getSelectStatus: 'orders/getSelectStatus',
      changeOrder: 'steps/getOrder',
      reorder: 'products/setOrder',
    }),
    ...mapMutations({
      setStep: 'steps/setStep',
    }),
    hasOrder() {
      return Object.keys(this.order).length
    },
    async goToOrder(id) {
      // localStorage.removeItem('order')
      await this.changeOrder(id)
      await this.$store.commit('steps/setLinesCuadro', [])
      await this.setStep(4)
      await this.$router.push({ name: 'steps' })
    },
    chargeData() {
      let orderBy = ''

      if (this.sortBy) {
        const type = this.sortDesc ? 'desc' : 'asc'
        orderBy = {
          column: this.sortBy,
          type,
        }
      }
      const userId = this.currentUser.id

      const status = this.status ? this.status.id : ''
      const client = this.client ? this.client.id : ''

      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        user_id: '',
        status_id: status,
        client_id: client,
        orderBy,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    dateColumnYear(time) {
      return TimestampToFlatPickrOnlyYear(time)
    },
    deleteOrder(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t('orders.delete_order_confirm', { order: this.numberFormat(item) }), {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(item.id)
          }
        })
    },
    priceFormatted(price) {
      const val = (price / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    numberFormat({ id, created_at }) {
      return `${this.dateColumnYear(created_at)}/T${id}`
    },
    deletePermission(order) {
      if (this.currentUser.roles[0].name === 'super_admin') {
        return true
      } if (['pending', 'budget', 'delivered'].includes(order.status.type) && ['user', 'admin'].includes(this.currentUser.roles[0].name)) {
        return true
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
